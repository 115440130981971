import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
	instructions = [
		"please_step_on_weight_scale"
	]
	instruction_audio = "05_1.mp3"
	values = ["wt", "ht"]

    constructor(port) {
		super();
		this.connect(port);
	}

	connect(port) {
		if(!this.isNative)return;
		// console.log(this.oldSerial)
		if(this.oldSerial===true) {
			this.port = new this.serialport(port,{ // eslint-disable-line no-undef
				baudRate: 19200,
				dataBits: 8,
				parity: 'none',
				stopBits: 1,
				autoOpen: false
			});
		} else {
			this.port = new this.serialport({ // eslint-disable-line no-undef
				path:port,
				baudRate: 19200,
				dataBits: 8,
				parity: 'none',
				stopBits: 1,
				autoOpen: false
			  });
		}
	}
	
    read(cb,errorcb,statuscb) {
		if(!this.isNative)return;

		this.port.open((err) => {
            console.log('Port opened.')
			if(err){
				console.log(err)
				statuscb({reading:false, error:true})
			} else {
				console.log("Port opened");
				setTimeout(() => {
					// this.port.write(Buffer.from([0x02, 0x68, 0x0C, 0x0A, 0x5A, 0x44, 0x26, 0x03]))
					statuscb({reading:true, error:false})
					this.parse(cb,errorcb,statuscb)
				}, 600)
			}
        })
	
		this.port.on('error', (err) => { 
			console.log('Error: ', err);
			statuscb({reading:false, error:true})
		});
	
		this.port.on('close', (err) => { 
			console.log("Device Closed. Error: ", err);  
			statuscb({reading:false, error:false})  
		});

		this.port.on('disconnect', (err) => { 
			console.log("Disconnected. Error: ", err);   
			statuscb({reading:false, error:true}) 
		});
	}
	
	parse(cb,errorcb,statuscb) {
		if(!this.isNative)return;
		var parser = this.port.pipe(new this.delimiter({ delimiter: Buffer.from([0x03]) }))

        parser.on('data', line => {
            console.log(line)
            const ascii = line.toString('ascii')
            const data = line.slice(6)
            if (ascii.search('ZK') === -1) {
                return
            }
            const height = addDot(data.slice(0, data.indexOf(0x1b)).toString('ascii'))

            let current = data.slice(data.indexOf(0x1b) + 1)
            const weight = addDot(current.slice(0, current.indexOf(0x1b)).toString('ascii'))

            current = current.slice(current.indexOf(0x1b) + 1)
            const bmi = addDot(current.slice(0, current.indexOf(0x1b)).toString('ascii'))

            console.log(data.toString('ascii'))
            console.log(height, weight, bmi)

			if(weight && height) {
				cb({
					wt: weight,
					ht: height
				})
				statuscb({reading:false, error:false})
				this.close();		
			}
		})
		
		function addDot(string) {
			if(string && string!="0") {
				if(string.substring(0, string.length - 1)) {
					return string.substring(0, string.length - 1)+"."+string.substring(string.length - 1)
				}
			}
			return ""
        }

        parser.on('error',  (err) => {
            console.error("parser Error", err);
			statuscb({reading:false, error:true})
			errorcb(err);
			this.close();
        });
	}
}
export default Device;