const DEVICES = [
    {
        type: 'weight_height',
        handle: 'WTHT_INBODY_1',
        name: 'InBody BSM370',
        hardwares: [
            {
                vendor_id: "0403", // FTDI
                product_id: "6001",
                serial_prefix: "HA" // Custom Serial
            }
        ]
    },
    {
        type: 'weight',
        handle: 'WT_TANITA_1',
        name: 'TANITA WB-380H',
        hardwares: [
            {
                vendor_id: "0557", // ATEN
                product_id: "2008",
            },
            {
                vendor_id: "0403", // FTDI
                product_id: "6001",
                serial_prefix: "A9" // Is embedded?
            },
            {
                vendor_id: "0403", // FTDI
                product_id: "6001",
                serial_prefix: "WA"
            }
        ]
    },
    {
        type: 'weight_height',
        handle: 'WTHT_NAGATA_1',
        name: 'NAGATA BW-2200P',
        hardwares: [
            {
                vendor_id: "0403", // FTDI
                product_id: "6001",
                serial_prefix: "WN"
            }
        ]
    },
    {
        type: 'weight',
        handle: 'WT_TSCALE_1',
        name: 'T-Scale M-310',
        hardwares: [
            {
                vendor_id: "0403", // FTDI
                product_id: "6001",
                serial_prefix: "WT"
            }
        ]
    },
    {
        type: 'bp',
        handle: 'BP_AND_1',
        name: 'A&D TM 2657P',
        hardwares: [
            {
                vendor_id: "067B", // Prolific
                product_id: "2303"
            },
            {
                vendor_id: "067B", // Prolific
                product_id: "23A3"
            },
            {
                vendor_id: "0403", // FTDI
                product_id: "6001",
                serial_prefix: "BA" // Custom Serial
            }
        ]
    },
    {
      type: 'bp',
      handle: 'OMRON_USB',
      name: 'OMRON 9030',
      hardwares: [
        {
          vendor_id: "0483", // USB
          product_id: "5740"
        },
        
      ]
    },
    {
        type: 'bp',
        handle: 'BP_RayCome_1',
        name: 'RayCome_1',
        hardwares: [
            {
              vendor_id: "0403", // FTDI
              product_id: "6001",
              serial_prefix: "BR" // Custom Serial
            }
        ]
    },
    {
        type: 'bp',
        handle: 'BP_Inbody_1',
        name: 'Inbody BPBIO 3205',
        hardwares: [
            {
              vendor_id: "0403", // FTDI
              product_id: "6001",
              serial_prefix: "BI" // Custom Serial
            }
        ]
    },
    {
        type: 'bp',
        handle: 'BP_Inbody_2',
        name: 'Inbody BPBIO 750',
        hardwares: [
            {
              vendor_id: "0403", // FTDI
              product_id: "6001",
              serial_prefix: "BJ" // Custom Serial
            }
        ]
    }
];
class NativeSerial {
    win = false;
    isNative = false;
    port = null;
    serialport = null;
    delimiter = null;
    oldSerial = false;
    constructor() {
        if (typeof (window.serialport) !== "undefined") {
            this.isNative = true;

            if(window.serialport.SerialPort!==undefined) {
                this.serialport = window.serialport.SerialPort; // eslint-disable-line no-undef
                this.delimiter = window.delimiter.DelimiterParser; // eslint-disable-line no-undef
                this.bytelength = window.serialport.ByteLengthParser; // eslint-disable-line no-undef
                // console.log(window.serialport.ByteLengthParser)
            } else {
                this.oldSerial = true;
                this.serialport = window.serialport; // eslint-disable-line no-undef
                this.delimiter = window.delimiter; // eslint-disable-line no-undef
                this.bytelength = window.serialport.parsers.ByteLength; // eslint-disable-line no-undef
            }
        }
    }

    listDevices(callback) {
        let result = [];
        // console.log(this.isNative)
        if(!this.isNative) {
            callback(result);
            return;
        }

        this.serialport.list().then(ports => {
            // console.log(ports)
            DEVICES.map((item) => {
                var device = null;
                ports.forEach( (port) => {
                    // console.log(port)
                    item.hardwares.forEach((hardware)=>{
                        if( hardware.serial_prefix ) {
                            if( port.serialNumber && port.serialNumber.startsWith(hardware.serial_prefix)
                                && hardware.vendor_id === port.vendorId 
                                && hardware.product_id === port.productId 
                            ) {
                                device = item
                                device.active = port.path;
                            } 
                        } else {
                            if( port.vendorId && port.productId && hardware.vendor_id === port.vendorId && hardware.product_id === port.productId ) {
                                device = item
                                device.active = port.path;
                            } 
                        }
                    });
                });
                if(device)result.push( device );
            });
            callback(result)
        });
    }


    close() {
        if(!this.isNative || !this.port)return;
        this.port.close(()=>{
            console.log('Port Closed')
        });
    }
}
export default NativeSerial;