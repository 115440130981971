import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
	instructions = [
		"please_step_on_weight_scale"
	]
	instruction_audio = "05_1.mp3"
	values = ["wt"]
	count = 0

    constructor(port) {
		super();
		this.connect(port);
	}

	connect(port) {
		console.log('connect')
		if(!this.isNative)return;


		if(this.oldSerial) {
			this.port = new this.serialport(port,{ // eslint-disable-line no-undef
				baudRate: 9600,
				dataBits: 8,
				parity: 'none',
				stopBits: 1
			});
		} else {
			this.port = new this.serialport({ // eslint-disable-line no-undef
				baudRate: 9600,
				dataBits: 8,
				parity: 'none',
				stopBits: 1,
				path:port
			  });
		}
	}
	
    read(cb,errorcb,statuscb) {
		if(!this.isNative)return;
		// console.log('read')
        this.port.on("open", (err) => {
			// console.log('open')
			if(err){
				console.log(err)
				statuscb({reading:false, error:true})
			} else {
				console.log("Port opened");
				this.parse(cb,errorcb,statuscb)
				statuscb({reading:true, error:false})
			}
		});
	
		this.port.on('error', (err) => { 
			console.log('Error: ', err);
			statuscb({reading:false, error:true})
		});
	
		this.port.on('close', (err) => { 
			console.log("Device Closed. Error: ", err);  
			statuscb({reading:false, error:false})  
		});

		this.port.on('disconnect', function (err) { 
			console.log("Disconnected. Error: ", err);   
			statuscb({reading:false, error:true}) 
		});
	}
	
	parse(cb,errorcb,statuscb) {
		if(!this.isNative)return;

		let parser = this.port.pipe(new this.delimiter({ delimiter: new Buffer.from('\n') })); // eslint-disable-line no-undef

		// var parser = this.port.pipe(Readline({delimiter: '\r\n'}));

		parser.on('data',  (data) => {
			let buf = Buffer.from(data, 'ascii')
			let raw = buf.toString('ascii')
			console.log(raw)
			let w = ''
			let startw = 5 //raw.indexOf('ST,GS')
			if(startw > 0) {
				w = raw.substr(startw, 8)
				w = w.trim()
			}
			this.count++
			if(w) {
				cb({
					wt: w
				})
			}
			if(w || this.count > 3) {
				statuscb({reading:false, error:false})
				this.close();
			}
		})

		// // var parser = this.port.pipe(new this.delimiter({ delimiter: new Buffer.from('EOL') }));  // eslint-disable-line no-undef
		// parser.on('data',  (data) => {

		// 	let buf = Buffer.from(data, 'ascii')
		// 	console.log(buf)
		// 	console.log(buf.toString('hex'))
		// 	console.log(buf.toString('ascii'))
		// 	// console.log(data)
		// 	// console.log(data.toString('ascii'))
		// 	// let raw = data.toString('ascii').split(',')
		// 	// let indexWt = raw.findIndex(element => element == 'Wk') + 1
		// 	// console.log(raw[indexWt])
		// 	// cb({
		// 	// 	wt: raw[indexWt]
		// 	// })
		// 	statuscb({reading:false, error:false})
			// this.close();

        parser.on('error',  (err) => {
            console.error("bpParser Error", err);
			statuscb({reading:false, error:true})
			errorcb(err);
			this.close();
        });
	}
}
export default Device;