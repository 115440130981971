import NativeSerial from "../NativeSerial";

class Device extends NativeSerial {
  instructions = [
    "please_insert_your_arm_into_bp_machine",
    "and_press_start_on_the_machine"
  ]
  instruction_audio = "04.mp3"
  values = ["sbp", "dbp", "pulse"]

  constructor(port) {
    super();
    this.connect(port);
  }

  connect(port) {
    if (!this.isNative) return;
		if(this.oldSerial) {
			this.port = new this.serialport(port,{ // eslint-disable-line no-undef
        baudRate: 9600,
        dataBits: 8,
        parity: 'none',
        stopBits: 1,
        autoOpen: true
			});
		} else {
      this.port = new this.serialport( { // eslint-disable-line no-undef
        baudRate: 9600,
        path:port,
        dataBits: 8,
        parity: 'none',
        stopBits: 1,
        autoOpen: true
      });
		}
  }

  read(cb, errorcb, statuscb) {
    if (!this.isNative) return;

    // this.port.open((err) => {
    //   if (err) {
    //     console.log(err)
    //     statuscb({ reading: false, error: true })
    //   } else {
    //     console.log('Port opened.')
    //   }
    // })

    this.port.on("open", (err) => {
			if(err){
				console.log(err)
				statuscb({reading:false, error:true})
			} else {
				console.log("Port opened");
				statuscb({reading:true, error:false})
			}
		});

    this.port.on('data', (data) => {
      try {
        console.log(data);
        this.parse(cb, errorcb, statuscb,data);
      } catch (err) {
        console.log('Oops');
      }
    });

    this.port.on('error', (err) => {
      console.log('Error: ', err);
      statuscb({ reading: false, error: true })
    });

    this.port.on('close', (err) => {
      console.log("Device Closed. Error: ", err);
      statuscb({ reading: false, error: false })
    });

    this.port.on('disconnect', (err) => {
      console.log("Disconnected. Error: ", err);
      statuscb({ reading: false, error: true })
    });
  }

  parse(cb, errorcb, statuscb,data) {
    let res = data.toString();
    // console.log(res)
    res = res.split(',');
    // console.log(res)
    if(Array.isArray(res) && res.length===11) {
      cb({
        sbp: res[7],
        dbp: res[8],
        pulse: res[9]
      })
      statuscb({ reading: false, error: false })
      this.close();
    } else {
      console.error("bpParser Error");
      statuscb({ reading: false, error: true })
      errorcb( 'bpParser Error' );
      this.close();
    }
  }
}
export default Device;