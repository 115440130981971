import React, { Component } from "react";
import { connect } from 'react-redux'
import { patientChange } from '../state/patient'

import authFetch from '../services/authFetch';

import Loading from '../components/Loading';

import { Translate } from "react-localize-redux";


class SplitQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            previous: null,
            stats: null,
            loading: false,
            number: null,
            saved: false,
            personal_list: []
        };
    }

    componentDidMount() {
        authFetch.get('personal', (result) => {
            this.setState({ loading: true })
            if (result.success) {
                this.loadVisit(result);
                this.setState({ loading: false })
            }
        });
    }


    loadVisit(result) {
        let personal_templates = result.personal_templates
        var personals = [];
        if(personal_templates) {
            personal_templates.map((item, index) => {
                if(item.choices) {

                } else {
                    item.choices = [];
                }
                this.props.patient.personals.map((vitem, vindex) => {
                    if (item.id === vitem.id) {
                        item.value = vitem.value;
                        item.note = vitem.note;
                        if (item.value) {
                            item.choices.map((choice) => {
                                if (item.value === choice.value) {
                                    item.show_text_input = choice.has_text_input
                                }
                            });
                        }
                    }
                });
                // console.log(item)
                if (item.value === undefined || item.value === null) {
                    item.choices.map((choice) => {
                        if (choice.is_default) {
                            item.value = choice.value
                            item.show_text_input = choice.has_text_input
                        }
                    });
                }
                if (item.value === undefined || item.value === null) {
                    item.value = null;//"1";
                    item.show_text_input = 0;
                }
                if (!item.note) {
                    item.note = "";
                }
                personals.push(item)
            });
        }
        var set = {
            personal_list: personals
        }
        this.setState(set);
        this.setState({ loading: false });
    }

    numberchange = (number) => {
        this.setState({ number: number });
    }

    doSubmit = () => {
        this.setState({ loading: true });

        var pers = [];
        this.state.personal_list.map((item, index) => {
            pers.push({ id: item.id, value: item.value, note: item.note });
        });
        var data = {
            vn: this.props.patient.vn,
            personal: pers,
            wt: this.props.patient.wt,
            ht: this.props.patient.ht,
            temp: this.props.patient.temp,
        }

        authFetch.post('visit/device', data, (result) => {
            this.setState({ loading: false, saved: true });
            this.togglePane()
        });
        return false;
    }

    handlePersonalsChange(selected, choice) {
        var pers = [];
        this.state.personal_list.map((item, index) => {
            if (item.id === selected.id) {
                // item.value = choice.value;
                if(item.value===choice.value) {
					item.value=null;
				} else {
					item.value=choice.value;
					item.show_text_input=choice.has_text_input;
				}
            }
            pers.push(item);
        });
        this.setState({ personal_list: pers });

        var personals = [];
        pers.map((item, index) => {
            personals.push({ id: item.id, value: item.value, note: item.note });
        });
        this.props.patientChange({
            personals: personals
        });
    }

    /*
	handlePersonalsNoteChange(selected, event) {
		var pers = [];
		this.state.personal_list.map((item, index) => {
			if (item.id === selected.id) {
				item.note = event.target.value;
				this.changeHeightTextarea(event.target)
			}
			pers.push(item);
		});
		this.setState({ personal_list: pers });
	}
    */

    handlePersonalsNoteChange(selected, event) {
        var pers = [];
        console.log(selected);
        this.state.personal_list.map((item, index) => {
            if (item.id === selected.id) {
                item.note = event.target.value;
            }
            pers.push(item);
        });
        this.setState({ personal_list: pers });

        var personals = [];
        pers.map((item, index) => {
            personals.push({ id: item.id, value: item.value, note: item.note });
        });
        this.props.patientChange({
            personals: personals
        });
    }

    handleVitalSignChange(type, event) {
        if(type==="ht") {
            this.props.patientChange({ht:event.target.value})
        } else if(type==="wt") {
            this.props.patientChange({wt:event.target.value})
        } else if(type==="temp") {
            this.props.patientChange({temp:event.target.value})
        }
    }

    togglePane = () => {
        this.props.patientChange({
            splitting: !this.props.patient.splitting
        });
    }

    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div>
                        {this.props.patient.splitting ?
                            <div className="split-page">
                                <h4>ค่าอื่นๆ</h4>
                                <div className="vital-signs">
                                    <div>
                                        <label>น้ำหนัก</label>
                                        <input type="text" placeholder="กก." className="form-control" onChange={(event) => this.handleVitalSignChange("wt", event)} value={this.props.patient.wt} />
                                    </div>
                                    <div>
                                        <label>ส่วนสูง</label>
                                        <input type="text" placeholder="ซม." className="form-control" onChange={(event) => this.handleVitalSignChange("ht", event)} value={this.props.patient.ht}/>
                                    </div>
                                    <div>
                                        <label>อุณหภูมิ</label>
                                        <input type="text" className="form-control" onChange={(event) => this.handleVitalSignChange("temp", event)} value={this.props.patient.temp}/>
                                    </div>
                                </div>
                                <h4>ซักประวัติ</h4>
                                <div className="pe-list">
                                    {/*this.state.personal_list.map((item, index) => {
                                        if (item.handle == 'wt' || item.handle == 'ht' || item.handle == 'temp') return null;
                                        return (
                                            <div className="pe-list-item" key={index}>
                                                <div className="pe-list-item-title">{item.name}:</div>
                                                {item.choices === null || item.choices.length === 0 ? (
                                                    <div className="pe-list-item-comment">
                                                        <textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePersonalsNoteChange(item, event)} value={item.note}></textarea>
                                                    </div>
                                                ) : (
                                                    <div className="pe-list-item-content">
                                                        <div className="pe-list-item-select">
                                                            {item.choices.map((choice, pindex) => {
                                                                return <button className="pe-list-item-radio" key={pindex} onClick={() => this.handlePersonalsChange(item, choice)}>
                                                                    <i className={"far fa-" + ((item.value === choice.value) ? 'check-circle' : 'circle')}></i>
                                                                    <strong><small> {choice.title}</small></strong>
                                                                </button>
                                                            })}
                                                        </div>
                                                        <div className="pe-list-item-note">
                                                            {item.show_text_input ? (
                                                                <textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePersonalsNoteChange(item, event)} value={item.note} />
                                                            ) : (null)}
                                                        </div>
                                                    </div>
                                                )}
                                                
                                            </div>
                                        )
                                    }) */}
                                    {this.state.personal_list.map((item, index) => {
                                        return (
                                            <div className="pe-list-item" key={index}>
                                                <div className="pe-list-item-title">{item.name}:</div>
                                                {item.choices === null || item.choices.length === 0 ? (
                                                    <div className="pe-list-item-comment">
                                                        <textarea rows="1" className="pe-note autosize" onChange={(event) => this.handlePersonalsNoteChange(item, event)} value={item.note}></textarea>
                                                    </div>
                                                ) : (
                                                    <div className="pe-list-item-content">
                                                        <div className="pe-list-item-select">
                                                            {item.choices.map((choice, pindex) => {
                                                                return <button className="pe-list-item-radio" key={pindex} onClick={() => this.handlePersonalsChange(item, choice)}>
                                                                    <i className={"far fa-" + ((item.value === choice.value) ? 'check-circle' : 'circle')}></i>
                                                                    <strong><small> {choice.title}</small></strong>
                                                                </button>
                                                            })}
                                                        </div>
                                                        <div className="pe-list-item-note">
                                                            {item.show_text_input ? (
                                                                <input type="text" className="pe-note" onChange={(event) => this.handlePersonalsNoteChange(item, event)} value={item.note} />
                                                            ) : (null)}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    })}
                                    {/* this.state.personal_list.map((item, index) => {
                                        if (item.handle !== 'fasting') return null;
                                        return (
                                            <div className="pe-list-item" key={index}>
                                                <div className="pe-list-item-title">{item.name}:</div>
                                                <div className="pe-list-item-content">
                                                    <div className="pe-list-item-select">
                                                        <button className="pe-list-item-radio" onClick={() => this.handlePersonalsChange(item.id, "0")}>
                                                            <i className={"far fa-" + ((item.value === "0") ? 'check-circle' : 'circle')}></i>
                                                            <strong> งด</strong>
                                                        </button>
                                                        <button className="pe-list-item-radio" onClick={() => this.handlePersonalsChange(item.id, "1")}>
                                                            <i className={"far fa-" + ((item.value === "1") ? 'check-circle' : 'circle')}></i>
                                                            <strong> ไม่งด</strong>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="pe-list-item-note">
                                                    {item.value === "1" ? (
                                                        <input type="text" className="pe-note" onChange={(event) => this.handlePersonalsNoteChange(item.id, event)} value={item.note}  placeholder="ทานล่าสุดเมื่อ" />
                                                    ) : (null)}
                                                </div>
                                            </div>
                                        )
                                    }) */}
                                </div>
                                <p className="text-right">
                                    <button className="btn-round" onClick={this.doSubmit}>{translate("save_data")}</button>
                                </p>
                            </div>
                            : null}
                        <button className="split-pane-toggle" onClick={this.togglePane}>
                            <i className={"fas fa-" + ((this.props.patient.splitting) ? 'times' : ((this.state.saved) ? 'check' : 'file-alt'))}></i>
                        </button>
                        <Loading show={this.state.loading} />
                    </div>
                }
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return { patient: state.patient }
};

export default connect(mapStateToProps, { patientChange })(SplitQuestion);
