import Auth from '../helpers/Auth';

let deviceAppID = localStorage.getItem('checkmate_device_id')
if(!deviceAppID) {
	deviceAppID = genuuidv4()
	localStorage.setItem('checkmate_device_id', deviceAppID);
}

function genuuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
const authFetch = {
	prefix: process.env.REACT_APP_SERVER_URL + '/api/v1/',
	headers: {},
	init() {
		this.headers = {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
			'X-Checkmate-Device': deviceAppID
		}
		if (Auth.isAuthenticated) {
			let authen_headers = { 'Authorization': 'Bearer ' + Auth.token, 'Checkmate-Unit': Auth.unit_id };
			this.headers = { ...this.headers, ...authen_headers }
		} 
		if(Auth.unit_id) {
			let authen_headers = { 'Checkmate-Unit': Auth.unit_id };
			this.headers = { ...this.headers, ...authen_headers }
		}
	},
	get(url, cb) {
		this.init();
		fetch(this.prefix + url, {
			method: 'GET',
			headers: this.headers
		})
		.then(checkError)
		.then((data) => data.json()).then((resp) => {
			cb(resp);
		}).catch((error) => {
			cb({ success: 0 });
			this.handleErrors(error);
		});
	},
	post(url, body, cb) {
		this.init();
		fetch(this.prefix + url, {
			method: 'POST',
			headers: this.headers,
			body: JSON.stringify(body)
		})
			.then(checkError)
			.then((data) => data.json()).then((resp) => {
				cb(resp);
			}).catch((error) => {
				cb({ success: 0 });
				this.handleErrors(error);
			});
	},
	put(url, body, cb) {
		this.init();
		fetch(this.prefix + url, {
			method: 'PUT',
			headers: this.headers,
			body: JSON.stringify(body)
		})
			.then(checkError)
			.then((data) => data.json()).then((resp) => {
				cb(resp);
			}).catch((error) => {
				cb({ success: 0 });
				this.handleErrors(error);
			});
	},
	handleErrors(response) {
		//console.log('handleerrors')
		if (!response.ok) {
			// window.doAlert('Error connecting to server');
			//throw Error(response.statusText);
		}
		return response;
	}
};

function checkError(res) {
	if (res.status === 200) {
		return res;
	} else if (res.status === 401) {
		//unauthorize
		Auth.logout();
		window.doAlert('Error, Unauthorize');
		throw new Error('Unauthorize');
	} else {
		window.doAlert('Error Connecting to Server');
		throw new Error('Server error');
	}
}
export default authFetch;

