import React, { Component } from "react";

import authFetch from '../services/authFetch';
import InlineLoading from '../components/InlineLoading';

class History extends Component {
	constructor(props) {
		super(props);

		this.state = {
			list: null,
			show: false
		};
		this.toggleShow = this.toggleShow.bind(this);
		this.toggleHide = this.toggleHide.bind(this);
	}

	componentDidMount() {
	}

	toggleShow() {
		this.listHistory();
		this.setState({show: true });
	}

	toggleHide() {
		this.setState({show: false });
	}
	
	listHistory = () => {
        authFetch.get('device/history', (result) => {
            // console.log(result)
            if (result.success && result.data) {
                this.setState({ loading: false, list: result.data });
            } else {
                this.setState({ loading: false, error_message: "ไม่พบข้อมูล" });
            }
        });
    };

	render() {
		return (
			<div className="history-wrapper">
				{this.state.show ? (
				<div className="history-list">
					<div className="history-list-header">
						<h4><i className="fa fa-history" aria-hidden="true"></i> History</h4>
						<button className="history-close" onClick={this.toggleHide}>&times;</button>
					</div>
					<div className="history-list-body">
					{this.state.list !== null ? (
						<table className="light-table">
							<thead>
								<tr>
									<th className="text-center">V.N.</th>
									<th className="text-center">Name</th>
									<th className="text-center">Value</th>
									<th className="text-center">Time</th>
								</tr>
							</thead>

							{this.state.list.length>0?(
							<tbody>
								{this.state.list.map((item, index) => {
									return (<tr key={index}>
										<td className="text-center">{item.vn}</td>
										<td className="text-center">{item.fullname}</td>
										<td className="text-center">{item.data}</td>
										<td className="text-center">{item.updated_at}</td>
									</tr>)
								})}
							</tbody>
							):(
								<tbody>
									<tr>
										<td colSpan={4}><strong>No Data</strong></td>
									</tr>
								</tbody>
							)}
						</table>
					) : (
						<InlineLoading show={true} />
					)}
					</div>
				</div>
				): null }
				<div className="history-toggle"  onClick={this.toggleShow}><i className="fa fa-history" aria-hidden="true"></i> History</div>
			</div>
		);
	}
}

export default History;