import React, { Component } from "react";
import {
  Link,
} from "react-router-dom";
import { Translate } from "react-localize-redux";
import { connect } from 'react-redux'
import { patientChange, patientClear } from '../state/patient'

import authFetch from '../services/authFetch';
import Loading from '../components/Loading';
import MainLogo from '../components/MainLogo';
import History from '../components/History';
import MiniLanguageToggle from '../components/MiniLanguageToggle';
import MiniClock from '../components/MiniClock';
import NativeSerial from "../helpers/NativeSerial";

class Home extends Component {
    serial = null;
    
	constructor(props) {
        super(props);
        this.state = {
            devices: []
        };
    }
  
    componentDidMount() {
        this.props.patientClear();

		this.serial = new NativeSerial();
		this.listDevices();
    }

	listDevices = () => {
        this.setState({loading:true})
		this.serial.listDevices((devices)=>{
            this.setState({devices:devices,loading:false})
		})
    }
    
	closeApp = () => {
		window.close();
	}
    
    render() {
		var deviceList = this.state.devices.map(function (device, index) {
			return (
                <Translate key={index}>
                { ({ translate }) => 
                    <div className="single-device">
                        <strong>{translate("measure_"+device.type)}</strong>
                        <small>{device.name}</small>
                    </div>
                }
                </Translate> 
            );
		});

        return (
            <Translate>
            { ({ translate }) => 
                <div className="page-center">
                    <MainLogo/>
                    
                    <div className="logout-wrapper">
                        <div className="menu-topbar">
                            <MiniClock/>
                            <MiniLanguageToggle/>
                        </div>
                        <div className="menu-topbar">
                            <button onClick={this.listDevices}><i className="fas fa-sync-alt"></i></button>
                            <button onClick={this.closeApp}><i className="fa fa-times"></i></button>
                        </div>
                    </div>
                
                    { this.state.devices.length > 0 ?
                        <div>
                            {deviceList}
                            <Link to={'/patient'}>
                                <button className="btn btn-lg-round" type="submit">{translate("getting_start")} <i className="fas fa-play-circle"></i></button>
                            </Link>
                        </div>
                    :
                        <div>
                            <h3>{translate("no_measuring_device_found")}</h3>
                            <button className="btn btn-lg-round" onClick={this.listDevices}>{translate("refresh")} <i className="fas fa-sync-alt"></i></button>
                        </div>
                    }
                    <Loading show={this.state.loading} />
                    <History/>
                </div>
            }
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return { patient: state.patient }
};

export default connect(mapStateToProps, { patientChange, patientClear })(Home);