import React, { Component } from "react";
import { connect } from 'react-redux'
import { patientChange } from '../state/patient'

import Loading from '../components/Loading';
import MainLogo from '../components/MainLogo';
import MiniLanguageToggle from '../components/MiniLanguageToggle';
import MiniClock from '../components/MiniClock';
import NativeSerial from "../helpers/NativeSerial";

import DeviceMeasure from '../components/DeviceMeasure';

import { Translate } from "react-localize-redux";

class PatientMeasure extends Component {
    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            deviceComponent: [],
            current: 0,
            done: false,
            ready: false,
            loading: true
        };
    }

    componentDidMount() {
		let serial = new NativeSerial();
        serial.listDevices((devices)=>{
            this.setState({devices:devices,ready:true,loading:false})
		})
    }

    componentWillUnmount() {
    }

    getComponent = (index) => {
        return <DeviceMeasure key={index} device={this.state.devices[index]} update={this.deviceUpdate} ht={this.props.patient.ht}/>
    }

    deviceUpdate = (values) => {
        // console.log('deviceUpdate:')
        // console.log(values)
        let current = this.state.current + 1
        if(values) {
            this.props.patientChange(values);
        }

        if(this.state.devices.length === current) {
            this.props.history.push('/patient/final');
        } else {
            this.setState({current:this.state.current+1})
        }
    }

    deviceError = (err) => {
        console.log('deviceError:')
        console.log(err)
    }

    render() {
        return (
            <Translate>
                {({ translate }) =>
                    <div className="page">
                        <main className="page-center">
                            <MainLogo />

                            <div className="logout-wrapper">
                                <div className="menu-topbar">
                                    <MiniClock />
                                    <MiniLanguageToggle />
                                </div>
                                <div className="menu-topbar">
                                    <button onClick={() => { this.props.history.push('/') }}><i className="fa fa-home"></i></button>
                                </div>
                            </div>
                            {this.state.ready?
                                <div>
                                    {this.state.devices.length === this.state.current? // done
                                        <div>DONE</div>
                                    :
                                        <div>
                                            {this.getComponent(this.state.current)}
                                        </div>
                                    }
                                </div>
                            :null}
                            
                        </main>
                        <Loading show={this.state.loading} />
                    </div>
                }
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return { patient: state.patient }
};

export default connect(mapStateToProps, { patientChange })(PatientMeasure);
